export const Menuitems = [
  {
    tittle: "Home",
    url: "/",
    cName: "nav-links",
  },
  {
    tittle: "Meet",
    url: "/meet",
    cName: "nav-links",
  },
  // {
  //   tittle: "Progress",
  //   url: "/progress",
  //   cName: "nav-links",
  // },
  // {
  //   tittle: "News",
  //   url: "#news",
  //   cName: "nav-links",
  // },
  // {
  //   tittle: "Progress",
  //   url: "/progress",
  //   cName: "nav-links-mobile",
  // },
];

// {
//   tittle: "Sign Up",
//   url: "#",
//   cName: "nav-links-mobile",
// },
