import College from "../Images_Data/College.jpg";
import Mahant from "../Images_Data/mahant3.png";
import Mahant1 from "../Images_Data/mahant5.jpg";
import Image_two from "../Images_Data/Image_two.jpg";
import Image_three from "../Images_Data/Image_three.jpg";
import Image_Four from "../Images_Data/Image_Four.jpg";
import Image_Five from "../Images_Data/Image_Five.jpg";
import Image_Six from "../Images_Data/Image_Six.jpg";
import Image_Seven from "../Images_Data/Image_Seven.jpg";
import Image_Eight from "../Images_Data/Image_Eight.jpg";
import Image_Nine from "../Images_Data/Image_Nine.jpg";
import Image_Ten from "../Images_Data/Image_Ten.jpg";
import Image_Eleven from "../Images_Data/Image_Elevenjpg.jpg";
import Image_Twelve from "../Images_Data/Image_Twelve.jpg";

//Issues Data
const WorkData = [
  {
    image: College,
    information:
      "लैंसडाउन विधानसभा के अंतर्गत तीनों राजकीय महाविद्यालय जयहरीखाल, रिखणीखाल एवं नैनीडांडा में राष्ट्रीय उच्चतर शिक्षा अभियान द्वारा ₹4.00 करोड़ की लागत से निर्मित पुस्तकालय का लोकार्पण किया।। पूरे प्रदेश में कार्यक्रम का शुभारंभ वर्चुअल के माध्यम से मा० मंत्री उच्च शिक्षा Dr Dhan Singh Rawat जी द्वारा किया गया।।",
    alt: "1",
    heading: "पूरे प्रदेश में कार्यक्रम का शुभारंभ",
    id: "one",
  },
  {
    image: Image_two,
    information:
      "विकासखंड नैनीडांडा के अंतर्गत, ₹49.69 लाख की लागत से द्वितीय चरण हेतु स्वीकृत ज्युंदालु- पातल तक मोटरमार्ग का शिलान्यास किया।। ",
    alt: "2",
    heading: "विकासखंड नैनीडांडा",
    id: "two",
  },
  {
    image: Image_three,
    information:
      "आज विकासखण्ड नैनीडांडा के अंतर्गत, ₹64.03 लाख की लागत से द्वितीय चरण हेतु स्वीकृत कांडी से नैखाना तक मोटरमार्ग का भूमिपूजन कर शुभारंभ किया।।",
    heading: "मोटरमार्ग का भूमिपूजन कर शुभारंभ किया।।",
    alt: "3",
    id: "three",
  },
  {
    image: Image_Four,
    information:
      "विकासखंड रिखणीखाल के अंतर्गत अंदर गाँव में ₹97.31 लाख की लागत से स्वीकृत मिनी स्टेडियम का शिलान्यास किया।। साथ ही न्याय पंचायत कोटड़ी सैंड में सभी महिला मंगल दलों को सम्मान निधि वितरीत की।।",
    alt: "1",
    heading: "विकासखंड रिखणीखाल",
    id: "one",
  },
  {
    image: Image_Five,
    information:
      "विकासखंड रिखणीखाल के अंतर्गत द्वारी भौन से नावे तक, ₹73.41 लाख की लागत से स्वीकृत मोटरमार्ग निर्माण का शिलान्यास किया।।",
    heading: "विकासखंड रिखणीखाल",
    alt: "2",
    id: "two",
  },
  {
    image: Image_Six,
    information:
      "विकासखंड नैनीडांडा के अंतर्गत भौन में जिला सहकारी बैंक की शाखा का उद्घाटन किया।। साथ ही द्वितीय चरण हेतु ₹99.09 लाख की लागत से स्वीकृत अपोला गांव से अपोलासेरा-नलय तक सड़क निर्माण हेतु शिलान्यास किया।।",
    heading: "जिला सहकारी बैंक की शाखा का उद्घाटन ।।",
    alt: "3",
    id: "three",
  },
  {
    image: Image_Seven,
    information:
      "आज लैंसडाउन विधानसभा के विकासखंड नैनीडांडा में प्रथम बार पधारे प्रदेश के युवा एवम ऊर्जावान मुख्यमंत्री श्री पुष्कर सिंह धामी जी और उत्तराखंड सरकार में कैबिनेट मंत्री श्री बंशीदर भगत जी द्वारा, ढौंटियाल-रथुआधाब-हल्दुखाल मोटरमार्ग को हॉटमिक्स , मरचूला-सुरायिखेत-बैजरो मोटरमार्ग का सुधारीकरण , कसड़ा-चौकीखल मोटरमार्ग का सुधारीकरण, धुमाकोट-पिपली मोटरमार्ग , रेवा पंपिंग योजना, धुमाकोट में सभागार आदि सहित लगभग ₹90 करोड़ की योजनाओं का शिलान्यास एवम लोकार्पण किया गया।। क्षेत्रीय जनता की ओर से मा० मुख्यमंत्री जी का बहुत बहुत आभार एवम धन्यवाद।।",
    heading: "ऊर्जावान मुख्यमंत्री श्री पुष्कर सिंह धामी ।।",
    alt: "ऊर्जावान मुख्यमंत्री श्री पुष्कर सिंह धामी ।।",
    id: "three",
  },
  {
    image: Image_Eight,
    information:
      "श्री सिद्धबली वार्षिक अनुष्ठान महोत्सव 2021 के प्रथम दिन मंदिर परिक्रमा के साथ तीन दिन तक चलने वाले एकादशीय कुण्डीय यज्ञ का शुभारं हुआ।।",

    heading: "।।जय बाबा की।",
    alt: "3",
    id: "three",
  },
  {
    image: Image_Nine,
    information:
      "श्री सिद्धबली बाबा वार्षिक अनुष्ठान महोत्सव में अंतिम दिन पधारे परम् पूजनीय जगद्गुरु शंकराचार्य राज राजेश्वरम जी महाराज जी एवम साथ में महामंडलेश्वर श्री ललितानंद जी महाराज का स्वागत कर आशीर्वाद प्राप्त किया।।",
    heading: "श्री सिद्धबली बाबा वार्षिक अनुष्ठान महोत्सव ।।",
    alt: "3",
    id: "three",
  },
  {
    image: Image_Ten,
    information:
      "आज विकासखण्ड नैनीडांडा के अंतर्गत, द्वितीय चरण हेतु ₹80.76 लाख की लागत से स्वीकृत खोली-खंदक तोक से स्वतंत्रता सैनानी मस्तराम सुंद्रियाल जी के पैतृक गांव पत-गांव तक मोटरमार्ग का शिलान्यास किया।।",
    heading: "विकासखण्ड नैनीडांडा",
    alt: "3",
    id: "three",
  },
  {
    image: Image_Eleven,
    information:
      "आज, अपने श्री सिद्धबली मातृत्व आश्रम सत्तीचौड़ में उत्तराखण्ड का लोक पर्व बगवाल धूम धाम से मनाया।।",
    heading: "सिद्धबली मातृत्व आश्रम",
    alt: "3",
    id: "three",
  },
  {
    image: Image_Twelve,
    information:
      "आज विकासखण्ड रिखणीखाल के अंतर्गत अटल उत्कृष्ट राजकीय माध्यमिक विद्यालय सिद्धखाल में ,₹85.30 लाख की लागत से निर्माणाधीन क्राफ्ट कक्ष, कंप्यूटर कक्ष, प्रयोगशाला कक्ष व शौचालय भवन का भूमि पूजन एवं शिल्यान्यास किया।।",
    heading: "अटल उत्कृष्ट राजकीय माध्यमिक विद्यालय",
    alt: "3",
    id: "three",
  },
];
export default WorkData;
//Issues Data

//Carousel Data
export let SliderData = [
  {
    content:
      "पद भाग्य से मिल सकता है, परंतु प्रतिष्ठा केवल पुरुषार्थ से मिलता है।",
  },
  {
    content:
      "सुख और दुख बराबर मिलते है, बस निर्णय अपने करना है की पहले क्या भोगना है।",
  },
  {
    content: "संसार में मनुष्य एकमात्र प्राणी है जिसका जहर उसके शब्दों में है।",
  },
];

//Carousel Data

//Footer Data
export const FooterData = [
  {
    url: "https://www.facebook.com/mahantdiliprawat/",
    CaName: "Facebook",
    Cname: "fab fa-facebook",
  },

  // { url: "/", CaName: "Google", Cname: "fas fa-envelope" },
  { url: "https://twitter.com/MahantDilipRwt", CaName: "Twitter", Cname: "fab fa-twitter" },
  // { url: "/", CaName: "Linked", Cname: "fab fa-linkedin" },
  // { url: "/", CaName: "Pint", Cname: "fab fa-pinterest" },
  { url: "https://www.instagram.com/mahantdiliprwt/", CaName: "Insta", Cname: "fab fa-instagram" },
];
//Footer

//Home Image Slider
export const SliderDataHome = [
  {
    title:
      " क्षेत्र के विकास एवं प्रगति हेतु हम संकल्पित है। सबका साथ, सबका विकास।।",
    image:Mahant1,
  },
  {
    title:
      " क्षेत्र के विकास एवं प्रगति हेतु हम संकल्पित है। सबका साथ, सबका विकास।।",
    image:Mahant,
    alt: "Datta",
  },
];
//Home Image Slider
